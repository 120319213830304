<template>
  <h2>Dit is geen fake news!</h2>

  <iframe id="10524617" allowtransparency="true" frameborder="0" style="width:100%;border:none;" src="//www.chess.com/emboard?id=10524617"></iframe>

  <img src="@/assets/hont.png" />

  <h1>OWEN VERLOOR VAN MICK MET SCHAKEN</h1>


  <p class="je-moeder-owen">deze foto is niet gefabriceerd</p>
  <div class=img name="img"></div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    //
  }
}
</script>

<style>

  body{
      width: 100%;
      height: 100vh;
      background-image: url('@/assets/YOLO_MONEY_SWAG.gif');
      margin: 0;
  }

  h1{
      text-align: center;
      margin-top: 2%;
      font-size: 100px;
      color: white;
  }

  h2{
      font-size: 50px;
      text-align: center;
      text-decoration: underline;
      color: white;
      margin: 0;
  }

  h4{
    font-size: 16px;
    text-align: center;
    color: white;
  }

  .img{
      width: 326px;
      height: 179px;
      background-image: url('@/assets/proof.png');
      background-size: contain;
      margin: auto;
  }

  img{
    position: absolute;
    top: 1em;
    left: 1em;
  }

  .je-moeder-owen{
      text-align: center;
      font-size: 20px;
      background-color: black;
      color: white;
      width: 326px;
      margin: auto;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  iframe{
    margin-top: 1em;
    height: 50vh;
  }

</style>